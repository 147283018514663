import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import axoisConfigUtil from "../utils/AxoisConfigUtil";

export const useGetProjectsStatistics = ({ projectId, year, month }) => {
  return useQuery(
    ["projects", projectId, year, month],
    () => {
      if (!projectId || !year || !month) {
        return Promise.reject(new Error("Missing required parameters"));
      }
      return axios
        .get(
          `${process.env.REACT_APP_API_URL}/statistics/v2/${projectId}/${year}-${month}`,
          axoisConfigUtil.getHeadersWithLangAndToken()
        )
        .then((res) => res.data);
    },
    {
      enabled: !!projectId && !!year && !!month,
      retry: false,
    }
  );
};
