import projectConstants from "../constants/ProjectConstants";
import videosConstants from "../constants/VideosConstants";

export const en = {
  translation: {
    general: {
      delete: "Delete",
      messages: {
        success: {
          SignedOut: "Signed out successfully",
        },
        error: {
          generalError: "General Error",
          connectionRefused: "Connection Refused",
          loginFirst: "Sign in First",
          userNotAuthorized: "User Not Authorized",
          loginAgain: "Please login Again",
        },
        info: {
          verificationInProgress: "Verification in progress",
          d: "Verification in progress",
        },
        confirm: {
          deleteProject: "Are You Sure You Want to Delete This Project?",
          deletePart: "Are You Sure You Want to Delete This Part?",
        },
      },
    },
    home: {
      header: "activid home page!",
      login: "Login",
      signUp: "sign up",
      main: "Home",
    },
    login: {
      email: "Email",
      password: "Password",
      logIn: "Log in",
      forgetPassword: "Forget Your Password?",
      messages: {
        success: {
          loggedInSuccessfully: "Logged in successfully",
        },
        error: {},
      },
    },
    signUp: {
      name: "Name",
      phone: "Phone",
      email: "Email",
      storeName: "Store \\ Website Name",
      storeUrl: "Store URL",
      password: "Password",
      passwordConfirmation: "Password Confirmation",
      iAgreeToThe: "I agree to the",
      termsAndConditions: "terms and conditions",
      and: "and",
      privacyPolicy: "privacy policy",
      register: "Register",
      messages: {
        success: {
          accountCreatedSuccessfully: "Account Created Successfully",
        },
        error: {},
      },
      noteHeader: "Thank you for your interest in registering at Activid",
      noteSubHeader: "You can register through the following channels",
      channel: "Channel",
      status: "Status",
      actions: "Actions",
      activid: "Activid",
      salla: "Salla",
      zid: "Zid",
      customWebsite: "Custom website ?",
      available: "Available",
      soon: "Soon",
      registerNow: "Register Now",
      tableCaption:
        "For notes, you can contact us through the following channels",
    },
    resetPassword: {
      email: "Your Email",
      reset: "Reset",
      resetPassword: "Reset Password",
      messages: {
        success: {},
        error: {},
      },
    },
    newPassword: {
      newPassword: "New Password",
      newPasswordConfirmation: "new Password Confirmation",
      save: "Save",
      messages: {
        success: {},
        error: {},
      },
    },
    userDashboard: {
      projects: "Projects",
      myVideos: "My Videos",
      settings: "Settings",
      packages: "Packages",
      building: "Building",
      rules: "Rules",
      design: "Design",
      deploy: "Deploy",
      statistics: "Statistics",
      subscriptions: "My Subscriptions",
    },
    building: {
      name: "Video name",
      buttons: "Buttons",
      buttonText: "Enter button text",
      anotherVideo: "Another video",
      buttonType: "Type",
      videos: "Videos",
      addVideo: "Add video",
      copyableText: "Copyable Text",
      externalLink: "External Link",
      addButton: "Add Button",
      submit: "Submit",
      selectNextVid: "Select next video",
      link: "link",
    },
    emptyState: {
      note: "Note",
      greeting: "Welcome ,",
      startNow: "Start now by creating your first interactive video!",
      createBtn: "Create",
      uploadBtn: "Upload",
      uploadAgainBtn: "Upload another file",
      uploadSizeMsg: "Please ensure that your file is no larger than",
      uploadMsg: "Drag & drop videos here, or click to select videos",
      videosLimit:
        "The number of videos allowed to upload based on your subscription is:",
      errorMessageMaxSize: "The file is too large, this size is not allowed",
      errorMessageGeneral: "Something went wrong, please try again!",
      errorMessageType: "The file type is not allowed",
      uploadedSuccessfully: "Uploaded successfully",
      errorMessageMaxFiles: `Cannot uplude more thean ${videosConstants.MAX_FILES} vidoes`,
      files: "Files",
    },
    newProject: {
      createIntractiveVid: "Create Intractive Video",
      clickToEdit: "Click on project to edit it:",
    },
    formik: {
      required: "Required",
      email: "must be as email format",
      phone: "Phone number is not valid",
      passwordConfirmation: "Passwords must match",
      minPassword: "The password should not be less than 8 characters",
      terms: "You must accept terms and conditions",
      validURL: "enter valid URL",
    },
    notSupportedPhoneScreen: {
      title: "Sorry",
      message: "The site is currently compatible with computers only",
      back: "Back",
    },
    projectDesign: {
      widgetDesign: "Widget Design",
      modalDesign: "Modal Design",
      soon: "Soon",
      direction: "Direction",
      shape: "Shape",
      caption: "Caption",
      captionPlaceholder: "Need help ?",
      borderColor: "Border Color",
      customGif: "Custom GIF",
      uploadGif: "Upload GIF",
      sytle: {
        LEFT: "Left",
        RIGHT: "Right",
        CIRCLE: "Circle",
        RECTANGULAR: "Rectangular",
      },
    },
    projectSettings: {
      projectName: "Project Name",
      projectNameCaptionPlaceholder: "Unnamed Project",
      deleteProject: "Delete Project",
    },
    projectItem: {
      unnamedProject: "Unnamed Project",
    },
    projectRules: {
      add: "Add Rule",
      urlsRules: "URLs Rule",
      equalsTo: "Equals to",
      pages: "Pages",
      pagesDescription: "Set pages that are allow display your Videos by URL's",
    },
    projectDeploy: {
      projectStatus: "Project Status",
      alertHeading: "Your project has been successfully deployed!",
      alertText: "To make changes, please suspend the project first",
      alertTextWarning:
        "The video may take up to 10-15 minutes to be displayed on the website, avoid stopping or suspending the project during this time.",
      retryAgain: "Retry again",
      notVerifiedEmail: {
        message:
          "I verified my account, but until now the project is NOT COMPLETE?",
        action: "Click here, let's check",
      },
      messages: {
        success: {
          accountActivated: "Your account has been activated",
        },
        error: {
          alreadySent: "Already sent, please wait",
          accountNotActivated: "Your account is not activated",
        },
      },
    },
    title: {
      projectName: "Activid",
      newPass: "New Password",
    },
    brief: {
      build: "From here, you can upload parts and control options",
      design:
        "From here, you can control how your project will look inside your website",
      rules: "From here, you can set rules for your project to appear",
      settings:
        "Here you can manage the project's settings and delete it if needed.",
      deploy:
        "Here you can see the status of your request and the next steps to publish it on your website.",
    },
    noMatchPage: {
      header: "Sorry, this URL does not exist",
      button: "Home page",
    },
    updatePasswordDialog: {
      updateMessageNote: "Your username & password was sent to your mail",
      updateMessageAction: "Update Password",
      update: "Update",
      messages: {
        success: {
          updatedSuccessfully: "The password has been updated successfully",
        },
        error: {},
      },
    },
    part: {
      disabledAddButton: `You cannot add more than ${projectConstants.MAX_OPTIONS_PER_PROJECT} options at the moment`,
    },
    partsList: {
      disabledAddVideo1: "You cannot add more than",
      disabledAddVideo2: "Video at the moment",
    },
    couponUseCount: {
      coupon: "Coupon",
      numberOfUsage: "Number of usage",
      stores: "Stores",
      store: "Store",
      subscriptionDate: "subscription date",
    },
  },
};
