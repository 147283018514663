import { useTranslation } from "react-i18next";
import { Navigate, Outlet } from "react-router-dom";
import errorConstants from "../../../constants/ErrorConstants";
import toastifyFile from "../../external-libraries/React-toastify/index";
import settingsConstants from "../../../constants/SettingsConstants";
import { IoLogoWhatsapp } from "react-icons/io5";
import axios from "axios";
import axoisConfigUtil from "../../../utils/AxoisConfigUtil";

function AuthDashboard() {
  const hasToken = localStorage.getItem("token") != null;
  const isPhoneScreen = document.body.clientWidth <= 900;
  const { t } = useTranslation();

  function goBack() {
    toastifyFile.errorNotify(
      t("general.messages.error.loginFirst") +
        " : " +
        errorConstants.authDashboard.code
    );
    return <Navigate to={"/login"} />;
  }

  function showNotSupportedPhoneScreen() {
    return <Navigate to={"/not-supported-phone-screen"} />;
  }

  function sendSupportRequest() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/notify-support`,
        {},
        axoisConfigUtil.getHeadersWithLangAndToken()
      )
      .then((response) => {
        if (response.status === 200) {
          window.open(
            `https://api.whatsapp.com/send?phone=${settingsConstants.ACTIVID_SUPPORT_NUMBER}`,
            "_blank"
          );
        }
      })
      .catch((error) => {
        toastifyFile.errorNotify(error.response.data.error);
      });
  }

  return !isPhoneScreen ? (
    hasToken ? (
      <>
        <Outlet />
        <div className='fixed bottom-10 left-10 rounded-full flex'>
          <IoLogoWhatsapp
            className='cursor-pointer w-14 h-14 text-green-600'
            onClick={() => {
              sendSupportRequest();
            }}
          />
        </div>
      </>
    ) : (
      goBack()
    )
  ) : (
    showNotSupportedPhoneScreen()
  );
}

export default AuthDashboard;
