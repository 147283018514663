import { Box, HStack, Stack, Text, Image } from "@chakra-ui/react";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { useState } from "react";

import totalOfVisitors from "../../../assets/images/dashboard/ProjectStatistics/Total of visitors.png";
import openWidget from "../../../assets/images/dashboard/ProjectStatistics/Open Widget.png";
import closeWidget from "../../../assets/images/dashboard/ProjectStatistics/closeWidget.png";
import closeModal from "../../../assets/images/dashboard/ProjectStatistics/closeModal.png";
import { useGetProjectsStatistics } from "../../../hooks/projectStatistics";
import StatisticsLists from "./StatisticsLists";
import statisticsDate from "../../../helpers/statisticsDate";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function ProjectStatistics({ projectId }) {
  const [selectedMonth, setSelectedMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const { data: projectStatistics } = useGetProjectsStatistics({
    projectId: projectId,
    year: selectedYear,
    month: selectedMonth,
  });

  return (
    <>
      <div
        className='flex gap-3 mt-12 mx-16 text-2xl font-bold justify-center items-center'
        dir='ltr'
      >
        <IoIosArrowDropleft
          className='text-3xl cursor-pointer select-none'
          onClick={() => {
            const currrentYear = new Date().getFullYear();
            if (selectedYear < currrentYear)
              setSelectedYear((prev) => prev + 1);
          }}
        />

        <span className='font-extrabold'>{selectedYear}</span>

        {months.map((m, i) => {
          const currrentMonth = new Date().getMonth();
          return (
            <div
              key={m}
              onClick={() => {
                const formattedMonth = (i + 1).toString().padStart(2, "0");
                setSelectedMonth(formattedMonth);
              }}
              className={`cursor-pointer ${
                selectedMonth === (i + 1).toString().padStart(2, "0")
                  ? "text-primary"
                  : i > currrentMonth
                  ? "text-[#A8A3A3] opacity-30"
                  : "text-[#A8A3A3]"
              }`}
            >
              {m}
            </div>
          );
        })}

        <IoIosArrowDropright
          className='text-3xl cursor-pointer'
          onClick={() => setSelectedYear((prev) => prev - 1)}
        />
      </div>
      <Stack className='m-16 mt-14'>
        <HStack justifyContent={"center"} gap='10'>
          <HStack
            bg='#fff8fa'
            alignContent={"center"}
            borderRadius='md'
            p={4}
            h={"fit-content"}
          >
            <Box>
              <Image boxSize='50px' src={totalOfVisitors} />
            </Box>
            <Text as='b'>
              Total of visitors
              <Text textAlign={"center"} color='#df0a56' fontSize='xl'>
                {projectStatistics?.events?.WIDGET_OPEN || 0}
              </Text>
            </Text>
          </HStack>

          <HStack
            bg='#e6fff8'
            alignContent={"center"}
            borderRadius='md'
            p={4}
            h={"fit-content"}
          >
            <Box>
              <Image boxSize='50px' src={openWidget} />
            </Box>
            <Text as='b'>
              OPEN ( Widget )
              <Text textAlign={"center"} color='#26a26b' fontSize='xl'>
                {projectStatistics?.events?.MODAL_OPEN || 0}
              </Text>
            </Text>
          </HStack>

          <HStack
            bg='#fbebff'
            alignContent={"center"}
            borderRadius='md'
            p={4}
            h={"fit-content"}
          >
            <Box>
              <Image boxSize='50px' src={closeWidget} />
            </Box>
            <Text as='b'>
              CLOSE ( Widget )
              <Text textAlign={"center"} color='#9508c9' fontSize='xl'>
                {projectStatistics?.events?.WIDGET_CLOSE || 0}
              </Text>
            </Text>
          </HStack>

          <HStack
            bg='#fff7f2'
            alignContent={"center"}
            borderRadius='md'
            p={4}
            h={"fit-content"}
          >
            <Box>
              <Image boxSize='50px' src={closeModal} />
            </Box>
            <Text as='b'>
              CLOSE ( Modal )
              <Text textAlign={"center"} color='#d36216' fontSize='xl'>
                {projectStatistics?.events?.MODAL_CLOSE || 0}
              </Text>
            </Text>
          </HStack>
        </HStack>
      </Stack>
      <StatisticsLists statistics={projectStatistics} />

      <Stack className='my-4 mx-16 text-sm font-semibold'>
        <p>
          آخر تحديث: <span dir='ltr'>{statisticsDate.current}</span>
        </p>
        <p>
          التحديث القادم: <span dir='ltr'>{statisticsDate.next}</span>
        </p>
      </Stack>
    </>
  );
}

export default ProjectStatistics;
