const formatDate = (date) => {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${hours}:${minutes} ${day}-${month}-${year}`;
};

const currentHour = new Date().getHours();
const firstDate = new Date();
firstDate.setHours(currentHour, 0, 0, 0);

const secondDate = new Date();
secondDate.setHours(currentHour + 1, 0, 0, 0);

const statisticsDate = {
  current: formatDate(firstDate),
  next: formatDate(secondDate),
};

export default statisticsDate;
