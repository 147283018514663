import finderIcon from "../../../assets/images/dashboard/ProjectStatistics/opreatingSystems/finder.svg";
import iosIcon from "../../../assets/images/dashboard/ProjectStatistics/opreatingSystems/ios.svg";
import windowsIcon from "../../../assets/images/dashboard/ProjectStatistics/opreatingSystems/windows.svg";
import andriodIcon from "../../../assets/images/dashboard/ProjectStatistics/opreatingSystems/andriod.svg";
import linuxIcon from "../../../assets/images/dashboard/ProjectStatistics/opreatingSystems/linux.svg";
import unknownIcon from "../../../assets/images/dashboard/ProjectStatistics/opreatingSystems/unknown.svg";

export default function StatisticsLists({ statistics }) {
  return (
    <div className='flex items-center justify-center gap-10 flex-wrap mb-5'>
      <div className='w-[300px] h-[360px] bg-[#EBF7FF] rounded-3xl overflow-y-scroll hide-scrollbar pb-1d0 pl-2 relative'>
        <p className='sticky top-0 h-10 w-full bg-[#EBF7FF] font-bold text-center pt-2 pb-4'>
          Operating system
        </p>
        {statistics?.os?.length ? (
          statistics.os.map((item, index) => {
            const keyLowerCased = item.name.toLocaleLowerCase();
            return (
              <div
                key={index}
                className='flex px-2 mt-2 items-center mb-4'
                dir='ltr'
              >
                <span className='w-6'>{index + 1}.</span>
                <img
                  className='w-6'
                  src={
                    keyLowerCased.includes("windows")
                      ? windowsIcon
                      : keyLowerCased.includes("ios")
                      ? iosIcon
                      : keyLowerCased.includes("android")
                      ? andriodIcon
                      : keyLowerCased.includes("linux") ||
                        keyLowerCased.includes("unix")
                      ? linuxIcon
                      : keyLowerCased.includes("mac")
                      ? finderIcon
                      : keyLowerCased.includes("unknown")
                      ? unknownIcon
                      : unknownIcon
                  }
                  alt={item.name}
                />
                <span className='ml-2 font-bold'>{item.name}</span>
                <span className='w-14 ml-auto'>{item.count}</span>
              </div>
            );
          })
        ) : (
          <div className='text-center mt-10 font-bold'>
            There are no statistics
          </div>
        )}
        <div className='sticky bottom-0 h-2 w-full bg-[#EBF7FF]'></div>
      </div>
      <div className='w-[300px] h-[360px] bg-[#EBF7FF] rounded-3xl overflow-y-scroll hide-scrollbar pb-1d0 pl-2 relative'>
        <p className='sticky top-0 h-10 w-full bg-[#EBF7FF] font-bold text-center pt-2 pb-4'>
          Country
        </p>
        {statistics?.countries?.length ? (
          statistics?.countries?.map((item, index) => {
            return (
              <div
                key={index}
                className='flex px-2 mt-2 items-center'
                dir='ltr'
              >
                <span className='w-6'>{index + 1}.</span>
                <span className='ml-2 w-8 text-xl'>{item.unicodeFlag}</span>
                <span className='font-bold'>{item.name}</span>
                <span className='w-14 ml-auto'>{item.count}</span>
              </div>
            );
          })
        ) : (
          <div className='text-center mt-10 font-bold'>
            There are no statistics
          </div>
        )}
        <div className='sticky bottom-0 h-2 w-full bg-[#EBF7FF]'></div>
      </div>
      <div className='w-[300px] h-[360px] bg-[#EBF7FF] rounded-3xl overflow-y-scroll hide-scrollbar pb-1d0 pl-2 relative'>
        <p className='sticky top-0 h-10 w-full bg-[#EBF7FF] font-bold text-center pt-2 pb-4'>
          URLs
        </p>
        {statistics?.url?.length ? (
          statistics.url.map((item, index) => {
            return (
              <div key={index} className='flex px-2 mt-2' dir='ltr'>
                <span className='w-6'>{index + 1}.</span>
                <a href={item.url} className='font-bold truncate mr-1'>
                  {item.url}
                </a>
                <span className='w-14 ml-auto'>{item.count}</span>
              </div>
            );
          })
        ) : (
          <div className='text-center mt-10 font-bold'>
            There are no statistics
          </div>
        )}
        <div className='sticky bottom-0 h-2 w-full bg-[#EBF7FF]'></div>
      </div>
    </div>
  );
}
